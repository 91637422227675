import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import parsePhoneNumber from 'libphonenumber-js'
import { format as formatNumerable } from 'numerable'

export function formatDate(value: string|Date, f?: string): string {
  if (!value) return ''
  if (!f) f = 'PPpp'

  const dt = typeof(value) === 'string' ? parseISO(value) : value
  return format(dt, f)
}

export function formatDateAgo(value?: string | Date): string {
  if (!value) return ''

  const dt = typeof(value) === 'string' ? parseISO(value) : value
  return formatDistanceToNow(dt)
}

export function formatNumber(value: string | number, f?: string, options?: any): string {
  if (!value) value = 0
  if (!f) f = '0,0'
  return formatNumerable(value, f, options)
}

export function formatPhoneNumber(value: string): string {
  if (!value) return ''
  const phoneNumber = parsePhoneNumber(value)
  if (!phoneNumber) return value
  return phoneNumber.formatNational()
}

export const formatTime = (time: number|undefined): string => {
  if (!time) return '00:00'

  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

export function parseStringISO(value?: string) {
  if (!value) return
  return parseISO(value)
}

export function phoneNumberFlag(value: string): string {
  const phoneNumber = parsePhoneNumber(value)
  if (!phoneNumber || !phoneNumber.country) return ''
  return getUnicodeFlagIcon(phoneNumber.country)
}
